/*
 * @Auth: linjituan
 * @Date: 2021-09-29 19:13:03
 * @LastEditors: linjituan
 * @LastEditTime: 2021-12-30 10:01:42
 */
import request from '@/utils/request'

// 查询人员台账列表
export function listEmployeeAccount(query) {
  return request({
    url: '/iot/employeeAccount/list',
    method: 'get',
    params: query
  })
}

// 查询人员台账详细
export function getEmployeeAccount(id) {
  return request({
    url: '/iot/employeeAccount/' + id,
    method: 'get'
  })
}

// 新增人员台账
export function addEmployeeAccount(data) {
  return request({
    url: '/iot/employeeAccount',
    method: 'post',
    data: data
  })
}

// 修改人员台账
export function updateEmployeeAccount(data) {
  return request({
    url: '/iot/employeeAccount',
    method: 'put',
    data: data
  })
}

// 删除人员台账
export function delEmployeeAccount(id) {
  return request({
    url: '/iot/employeeAccount/' + id,
    method: 'delete'
  })
}

// 导出人员台账
export function exportEmployeeAccount(query) {
  return request({
    url: '/iot/employeeAccount/export',
    method: 'get',
    params: query
  })
}

// 下载用户导入模板
export function importTemplate() {
  return request({
    url: '/iot/employeeAccount/importTemplate',
    method: 'get'
  })
}

// 导入用户
export function importData(data) {
  return request({
    url: '/iot/employeeAccount/importData',
    method: 'post',
    data: data
  })
}

// 获取部门下拉树及人员设备
export function employeeAccountTree() {
  return request({
    url: '/iot/employeeAccount/tree',
    method: 'get'
  })
}
