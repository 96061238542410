import request from '@/utils/request'

// 查询加油记录列表
export function listVehicleFuelFillingRecord(query) {
  return request({
    url: '/iot/vehicleFuelFillingRecord/list',
    method: 'get',
    params: query
  })
}

// 查询加油记录详细
export function getVehicleFuelFillingRecord(id) {
  return request({
    url: '/iot/vehicleFuelFillingRecord/' + id,
    method: 'get'
  })
}

// 新增加油记录
export function addVehicleFuelFillingRecord(data) {
  return request({
    url: '/iot/vehicleFuelFillingRecord',
    method: 'post',
    data: data
  })
}

// 修改加油记录
export function updateVehicleFuelFillingRecord(data) {
  return request({
    url: '/iot/vehicleFuelFillingRecord',
    method: 'put',
    data: data
  })
}

// 删除加油记录
export function delVehicleFuelFillingRecord(id) {
  return request({
    url: '/iot/vehicleFuelFillingRecord/' + id,
    method: 'delete'
  })
}

// 导出加油记录
export function exportVehicleFuelFillingRecord(query) {
  return request({
    url: '/iot/vehicleFuelFillingRecord/export',
    method: 'get',
    params: query
  })
}

// 查询加油记录列表
export function getLastRecord(data) {
  return request({
    url: '/iot/vehicleFuelFillingRecord/getLastRecord',
    method: 'post',
    data: data
  })
}
// 导入模板下载
export function importTemplate(query) {
  return request({
    url: '/iot/vehicleFuelFillingRecord/importTemplate',
    method: 'get',
    params: query
  })
}
// 加油卡EXCEL导入
export function importData(data) {
  return request({
    url: '/iot/vehicleFuelFillingRecord/importData',
    method: 'post',
    data: data
  })
}
