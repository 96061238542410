<template>
  <a-drawer
    width="35%"
    :label-col="4"
    :wrapper-col="14"
    :visible="open"
    @close="onClose"
    :title="formTitle"
    :maskClosable="false"
  >
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="车辆编号" prop="vehicleNo">
        <a-select
          show-search
          allowClear
          placeholder="请输入车辆自编号模糊查找"
          :default-active-first-option="false"
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="null"
          @search="handleVehicleNoSearch"
          @change="handleVehicleNoChange"
          :loading="loading"
          v-model="form.vehicleNo"
        >
          <a-select-option v-for="d in vehicleNoArray" :key="d.vehicleNo" :value="d.vehicleNo">
            {{ d.vehicleNo }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="车牌号" prop="licensePlateNum">
        <a-select
          show-search
          allowClear
          placeholder="请输入车辆车牌号模糊查找"
          :default-active-first-option="false"
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="null"
          @search="handleLicensePlateNumSearch"
          @change="handleLicensePlateNumChange"
          :loading="loading"
          v-model="form.licensePlateNum"
        >
          <a-select-option v-for="d in licensePlateNumArray" :key="d.licensePlateNum" :value="d.licensePlateNum">
            {{ d.licensePlateNum }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="加油人" prop="employeeId">
        <a-select
          show-search
          placeholder="请输入加油人姓名查找"
          :default-active-first-option="false"
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="null"
          @search="handlePersonnelSearch"
          @change="handlePersonnelChange"
          :loading="loading"
          v-model="form.employeeId"
        >
          <a-select-option v-for="d in personnelArray" :key="d.id">
            {{ d.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="加油时间" prop="fillingTime">
        <a-date-picker
          style="width: 100%"
          v-model="form.fillingTime"
          value-format="YYYY-MM-DD HH:mm:ss"
          format="YYYY-MM-DD"
          allow-clear
        />
      </a-form-model-item>
      <a-form-model-item label="加油金额" prop="amount">
        <a-input v-model="form.amount" placeholder="请输入加油金额" />
      </a-form-model-item>
      <a-form-model-item label="所加的油类型" prop="oilType">
        <a-select placeholder="请选择所加的油类型" v-model="form.oilType" @change="onOilTypeChange">
          <a-select-option v-for="(d, index) in oilTypeOptions" :key="index" :value="d.dictValue">{{
            d.dictLabel
          }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="加油量" prop="oilQuantity">
        <a-input v-model="form.oilQuantity" placeholder="请输入加油量" @change="handleOilQuantityChange" />
      </a-form-model-item>
      <a-form-model-item label="油标号" prop="oilLabel">
        <a-select placeholder="请选择所加的油类型" v-model="form.oilLabel">
          <a-select-option v-for="(d, index) in oilLabelOptions[form.oilType]" :key="index" :value="d.dictValue">{{
            d.dictLabel
          }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="单价" prop="price">
        <a-input v-model="form.price" placeholder="请输入单价" />
      </a-form-model-item>
      <a-form-model-item label="当前里程" prop="mileage">
        <a-input v-model="form.mileage" placeholder="请输入当前里程" @change="handleMileageChange" />
      </a-form-model-item>
      <a-form-model-item label="上传油票拍照" prop="fuelCouponImage">
        <!-- <file-upload
          v-model="form.fuelCouponImage"
          :params="paramsFuelCouponImagePhoto"
          type="image"
          @getfileName="getfileNameFuelCouponImagePhoto"
        ></file-upload> -->
        <file-upload v-model="form.fuelCouponImage" folder="vehicleFuelFillingRecord" type="image"></file-upload>
      </a-form-model-item>
      <a-form-model-item label="上传车辆里程实拍" prop="mileageImage">
        <!-- <file-upload
          v-model="form.mileageImage"
          :params="paramsMileageImagePhoto"
          type="image"
          @getfileName="getfileNameMileageImagePhoto"
        ></file-upload> -->
        <file-upload v-model="form.mileageImage" folder="vehicleFuelFillingRecord" type="image"></file-upload>
        <!-- <file-upload v-model="form.mileageImage"></file-upload> -->
      </a-form-model-item>
      <a-form-model-item label="上次加油时里程" prop="lastMileage" class="item-label">
        <span>{{ form.lastMileage }}</span>
      </a-form-model-item>
      <a-form-model-item label="比上次里程新增" prop="increasedMileage" class="item-label">
        <span>{{ form.increasedMileage }}</span>
      </a-form-model-item>
      <a-form-model-item
        label="最近1次百公里油耗"
        prop="oilConsumption100kmPer"
        :class="[isRedInfo ? 'item-label-red' : '']"
      >
        <span>{{ form.oilConsumption100kmPer }}</span>
      </a-form-model-item>
      <a-form-model-item label="最近6次百公里油耗" prop="oilConsumption100kmSix" class="item-label">
        <span>{{ form.oilConsumption100kmSix }}</span>
      </a-form-model-item>
      <a-form-model-item label="相似车型百公里油耗" prop="oilConsumption100kmStandard" class="item-label">
        <span>{{ form.oilConsumption100kmStandard }}</span>
      </a-form-model-item>
      <a-form-model-item label="本车绑定的加油卡号" prop="oilCardNo">
        <a-input v-model="form.oilCardNo" placeholder="" :disabled="true" />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark">
        <a-input v-model="form.remark" placeholder="请输入备注" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import debounce from 'lodash/debounce'
import {
  getVehicleFuelFillingRecord,
  addVehicleFuelFillingRecord,
  updateVehicleFuelFillingRecord,
  getLastRecord
} from '@/api/iot/vehicleFuelFillingRecord'
import { searchCarNo } from '@/api/iot/vehicleAccount'
import { listEmployeeAccount } from '@/api/iot/employeeAccount'
import { parseDateTime } from '@/utils/ruoyi'
export default {
  name: 'CreateForm',
  props: {
    // oilTypeOptions: {
    //   type: Array,
    //   required: true
    // },
    // oilLabelOptions: {
    //   type: Array,
    //   required: true
    // }
  },
  components: {},
  data() {
    this.handleVehicleNoSearch = debounce(this.handleVehicleNoSearch, 500)
    this.handleLicensePlateNumSearch = debounce(this.handleLicensePlateNumSearch, 500)
    this.handlePersonnelSearch = debounce(this.handlePersonnelSearch, 500)
    this.handleMileageChange = debounce(this.handleMileageChange, 500)
    this.handleOilQuantityChange = debounce(this.handleOilQuantityChange, 500)
    return {
      loading: false,
      formTitle: '',
      // 表单参数
      form: {
        vehicleNo: null,
        licensePlateNum: null,
        oilCardNo: null,
        fillingBy: null,
        fillingTime: null,
        amount: null,
        oilType: null,
        oilQuantity: null,
        oilLabel: null,
        price: null,
        mileage: null,
        remark: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        vehicleNo: [{ required: true, message: '车辆编号不能为空', trigger: 'blur' }],
        employeeId: [{ required: true, message: '加油人不能为空', trigger: 'blur' }],
        fillingTime: [{ required: true, message: '加油时间不能为空', trigger: 'blur' }],
        amount: [{ required: true, message: '加油金额不能为空', trigger: 'blur' }],
        oilType: [{ required: true, message: '所加的油类型不能为空', trigger: 'change' }],
        oilQuantity: [{ required: true, message: '加油量不能为空', trigger: 'blur' }],
        oilLabel: [{ required: true, message: '油标号不能为空', trigger: 'blur' }],
        price: [{ required: true, message: '单价不能为空', trigger: 'blur' }],
        mileage: [{ required: true, message: '加油时车辆里程数不能为空', trigger: 'blur' }]
      },
      personnelArray: [],
      fuelCouponImagePhoto: null,
      mileageImagePhoto: null,
      oilTypeOptions: [
        {
          dictLabel: '汽油',
          dictValue: '4'
        },
        {
          dictLabel: '柴油',
          dictValue: '5'
        },
        {
          dictLabel: '燃气',
          dictValue: '6'
        }
      ],
      oilLabelOptions: {
        '4': [
          {
            dictValue: '98',
            dictLabel: '#98'
          },
          {
            dictValue: '95',
            dictLabel: '#95'
          },
          {
            dictValue: '92',
            dictLabel: '#92'
          }
        ],
        '5': [
          {
            dictValue: '10',
            dictLabel: '#10'
          },
          {
            dictValue: '5',
            dictLabel: '#5'
          },
          {
            dictValue: '0',
            dictLabel: '#0'
          },
          {
            dictValue: '-10',
            dictLabel: '#-10'
          },
          {
            dictValue: '-20',
            dictLabel: '#-20'
          },
          {
            dictValue: '-35',
            dictLabel: '#-35'
          },
          {
            dictValue: '-50',
            dictLabel: '#-50'
          }
        ],
        '6': [
          {
            dictValue: '标准燃气',
            dictLabel: '标准燃气'
          }
        ]
      },
      isRedInfo: false,
      licensePlateNumArray: [],
      vehicleNoArray: []
    }
  },
  filters: {},
  created() {},
  computed: {
    paramsFuelCouponImagePhoto() {
      return {
        fileName: 'FuelCouponImage' + parseDateTime(new Date(), 'yyyyMMddHHmmss'),
        type: 'aliyunoss',
        folder: 'vehicleFuelFillingRecord'
      }
    },
    paramsMileageImagePhoto() {
      return {
        fileName: 'MileageImage' + parseDateTime(new Date(), 'yyyyMMddHHmmss'),
        type: 'aliyunoss',
        folder: 'vehicleFuelFillingRecord'
      }
    }
  },
  watch: {},
  mounted() {},
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        // vehicleNo: null,
        // licensePlateNum: null,
        oilCardNo: null,
        employeeId: null,
        fillingTime: null,
        amount: null,
        oilType: null,
        oilQuantity: null,
        oilLabel: null,
        price: null,
        mileage: null,
        remark: null
      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
      this.form.fillingTime = parseDateTime(new Date(), 'yyyy-MM-dd HH:mm:ss')
      this.form.status = 0
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getVehicleFuelFillingRecord(id).then(response => {
        this.form = response.data
        this.personnelArray = [{ id: this.form.employeeId, name: this.form.fillingBy }]
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function() {
      // this.form.fuelCouponImage = this.fuelCouponImagePhoto
      // this.form.mileageImage = this.mileageImagePhoto
      if (!this.form.oilCardNo) {
        this.$warning({
          title: 'Warning',
          content: '请先绑定加油卡号！'
        })
        return
      }
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.id !== undefined && this.form.id !== null) {
            updateVehicleFuelFillingRecord(this.form).then(response => {
              this.$message.success('修改成功', 3)
              this.open = false
              this.$emit('ok')
            })
          } else {
            addVehicleFuelFillingRecord(this.form).then(response => {
              this.$message.success('新增成功', 3)
              this.open = false
              this.$emit('ok')
            })
          }
        } else {
          return false
        }
      })
    },
    handleVehicleNoSearch(value) {
      console.log('handleVehicleNoSearch', value)
      let queryParam = {
        vehicleNo: value
      }
      if (value && !value.trim()) {
        queryParam = {
          pageNum: 1,
          pageSize: 10
        }
      }
      this.loading = true
      searchCarNo(queryParam).then(response => {
        this.vehicleNoArray = response
        this.loading = false
      })
    },
    handleVehicleNoChange(value) {
      const val = value === undefined ? '' : value
      this.form.vehicleNo = val
      if (val === '') {
        this.form.licensePlateNum = ''
      }
      // 设置车牌号
      const tmpArray = this.vehicleNoArray.filter(p => p.vehicleNo === val)
      if (tmpArray.length > 0) {
        this.form.licensePlateNum = tmpArray[0].licensePlateNum
        this.$forceUpdate()
      }
      // 同时调用接口
      this.doCheckVehicleNo(value)
    },
    getLastRecordEx(params) {
      this.isRedInfo = false
      getLastRecord(params)
        .then(response => {
          if (!this.form.employeeId && response.employeeId && !this.form.fillingBy && response.fillingBy) {
            this.personnelArray = [{ id: response.employeeId, name: response.fillingBy }]
            this.form.employeeId = response.employeeId
            this.form.fillingBy = response.fillingBy
          }
          if (!this.form.oilType && response.oilType) {
            this.form.oilType = response.oilType
          }
          if (!this.form.oilLabel && response.oilLabel) {
            this.form.oilLabel = response.oilLabel
          }
          this.form.lastMileage = response.lastMileage
          this.form.increasedMileage = response.increasedMileage
          this.form.oilConsumption100kmPer = response.oilConsumption100kmPer
          this.form.oilConsumption100kmSix = response.oilConsumption100kmSix
          this.form.oilConsumption100kmStandard = response.oilConsumption100kmStandard
          this.form.oilCardNo = response.oilCardNo
          const oilConsumption100kmPer = Number(this.form.oilConsumption100kmPer) // 20
          const oilConsumption100kmStandard = Number(this.form.oilConsumption100kmStandard) // 90
          if (oilConsumption100kmStandard > 0) {
            if ((oilConsumption100kmPer - oilConsumption100kmStandard) / oilConsumption100kmStandard > 0.2) {
              this.isRedInfo = true
            }
          }
          this.$forceUpdate()
          console.log('red')
        })
        .catch(obj => {
          if (obj.code === 400) {
            this.form.vehicleNo = ''
            this.form.licensePlateNum = ''
          }
        })
    },
    handleOilQuantityChange(e) {
      if (Number(this.form.mileage) > 0 && Number(this.form.oilQuantity) > 0) {
        const params = {
          currentMileage: this.form.mileage,
          licensePlateNum: '',
          oilQuantity: Number(e.target.value),
          vehicleNo: this.form.vehicleNo
        }
        this.getLastRecordEx(params)
      }
    },
    handleMileageChange(e) {
      if (Number(this.form.mileage) > 0 && Number(this.form.oilQuantity) > 0) {
        const params = {
          currentMileage: Number(e.target.value),
          licensePlateNum: '',
          oilQuantity: this.form.oilQuantity,
          vehicleNo: this.form.vehicleNo
        }
        this.getLastRecordEx(params)
      }
    },

    handleLicensePlateNumSearch(value) {
      console.log('handleLicensePlateNumSearch', value)
      let queryParam = {
        licensePlateNum: value
      }
      if (value && !value.trim()) {
        queryParam = {
          pageNum: 1,
          pageSize: 10
        }
      }
      this.loading = true
      searchCarNo(queryParam).then(response => {
        this.licensePlateNumArray = response
        this.loading = false
      })
    },
    handleLicensePlateNumChange(value) {
      const val = value === undefined ? '' : value
      this.form.licensePlateNum = val
      if (val === '') {
        this.form.vehicleNo = ''
      }
      // 设置车辆自编号
      const tmpArray = this.licensePlateNumArray.filter(p => p.licensePlateNum === val)
      if (tmpArray.length > 0) {
        this.form.vehicleNo = tmpArray[0].vehicleNo
        this.$forceUpdate()

        // 同时调用接口
        this.doCheckVehicleNo(this.form.vehicleNo)
      }
    },
    doCheckVehicleNo(vehicleNo) {
      if (!vehicleNo) {
        return
      }
      const params = {
        currentMileage: 0,
        licensePlateNum: '',
        oilQuantity: 0,
        vehicleNo: vehicleNo
      }
      this.getLastRecordEx(params)
    },
    handlePersonnelSearch(value) {
      console.log('handlePersonnelSearch', value)
      let queryParam = {
        name: value
      }
      if (value && !value.trim()) {
        queryParam = {
          pageNum: 1,
          pageSize: 10
        }
      }
      this.loading = true
      listEmployeeAccount(queryParam).then(response => {
        this.personnelArray = response.rows
        this.loading = false
      })
    },
    // 加油人
    handlePersonnelChange(value) {
      console.log('handlePersonnelChange', value)
      // this.form.employeeId = value
      const tmpArray = this.personnelArray.filter(p => p.id === value)
      if (tmpArray.length === 1) {
        this.form.fillingBy = tmpArray[0].name
      }
    },
    getfileNameFuelCouponImagePhoto(fileName) {
      this.fuelCouponImagePhoto = fileName
    },
    getfileNameMileageImagePhoto(fileName) {
      this.mileageImagePhoto = fileName
    },
    onOilTypeChange(e) {
      this.form.oilLabel = ''
    }
  }
}
</script>
<style scoped>
.item-label-red {
  color: red;
}
</style>
