var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        width: "35%",
        "label-col": 4,
        "wrapper-col": 14,
        visible: _vm.open,
        title: _vm.formTitle,
        maskClosable: false,
      },
      on: { close: _vm.onClose },
    },
    [
      _c(
        "a-form-model",
        { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "车辆编号", prop: "vehicleNo" } },
            [
              _c(
                "a-select",
                {
                  attrs: {
                    "show-search": "",
                    allowClear: "",
                    placeholder: "请输入车辆自编号模糊查找",
                    "default-active-first-option": false,
                    "show-arrow": false,
                    "filter-option": false,
                    "not-found-content": null,
                    loading: _vm.loading,
                  },
                  on: {
                    search: _vm.handleVehicleNoSearch,
                    change: _vm.handleVehicleNoChange,
                  },
                  model: {
                    value: _vm.form.vehicleNo,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "vehicleNo", $$v)
                    },
                    expression: "form.vehicleNo",
                  },
                },
                _vm._l(_vm.vehicleNoArray, function (d) {
                  return _c(
                    "a-select-option",
                    { key: d.vehicleNo, attrs: { value: d.vehicleNo } },
                    [_vm._v(" " + _vm._s(d.vehicleNo) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "车牌号", prop: "licensePlateNum" } },
            [
              _c(
                "a-select",
                {
                  attrs: {
                    "show-search": "",
                    allowClear: "",
                    placeholder: "请输入车辆车牌号模糊查找",
                    "default-active-first-option": false,
                    "show-arrow": false,
                    "filter-option": false,
                    "not-found-content": null,
                    loading: _vm.loading,
                  },
                  on: {
                    search: _vm.handleLicensePlateNumSearch,
                    change: _vm.handleLicensePlateNumChange,
                  },
                  model: {
                    value: _vm.form.licensePlateNum,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "licensePlateNum", $$v)
                    },
                    expression: "form.licensePlateNum",
                  },
                },
                _vm._l(_vm.licensePlateNumArray, function (d) {
                  return _c(
                    "a-select-option",
                    {
                      key: d.licensePlateNum,
                      attrs: { value: d.licensePlateNum },
                    },
                    [_vm._v(" " + _vm._s(d.licensePlateNum) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "加油人", prop: "employeeId" } },
            [
              _c(
                "a-select",
                {
                  attrs: {
                    "show-search": "",
                    placeholder: "请输入加油人姓名查找",
                    "default-active-first-option": false,
                    "show-arrow": false,
                    "filter-option": false,
                    "not-found-content": null,
                    loading: _vm.loading,
                  },
                  on: {
                    search: _vm.handlePersonnelSearch,
                    change: _vm.handlePersonnelChange,
                  },
                  model: {
                    value: _vm.form.employeeId,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "employeeId", $$v)
                    },
                    expression: "form.employeeId",
                  },
                },
                _vm._l(_vm.personnelArray, function (d) {
                  return _c("a-select-option", { key: d.id }, [
                    _vm._v(" " + _vm._s(d.name) + " "),
                  ])
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "加油时间", prop: "fillingTime" } },
            [
              _c("a-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  "value-format": "YYYY-MM-DD HH:mm:ss",
                  format: "YYYY-MM-DD",
                  "allow-clear": "",
                },
                model: {
                  value: _vm.form.fillingTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "fillingTime", $$v)
                  },
                  expression: "form.fillingTime",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "加油金额", prop: "amount" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入加油金额" },
                model: {
                  value: _vm.form.amount,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "amount", $$v)
                  },
                  expression: "form.amount",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "所加的油类型", prop: "oilType" } },
            [
              _c(
                "a-select",
                {
                  attrs: { placeholder: "请选择所加的油类型" },
                  on: { change: _vm.onOilTypeChange },
                  model: {
                    value: _vm.form.oilType,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "oilType", $$v)
                    },
                    expression: "form.oilType",
                  },
                },
                _vm._l(_vm.oilTypeOptions, function (d, index) {
                  return _c(
                    "a-select-option",
                    { key: index, attrs: { value: d.dictValue } },
                    [_vm._v(_vm._s(d.dictLabel))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "加油量", prop: "oilQuantity" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入加油量" },
                on: { change: _vm.handleOilQuantityChange },
                model: {
                  value: _vm.form.oilQuantity,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "oilQuantity", $$v)
                  },
                  expression: "form.oilQuantity",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "油标号", prop: "oilLabel" } },
            [
              _c(
                "a-select",
                {
                  attrs: { placeholder: "请选择所加的油类型" },
                  model: {
                    value: _vm.form.oilLabel,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "oilLabel", $$v)
                    },
                    expression: "form.oilLabel",
                  },
                },
                _vm._l(
                  _vm.oilLabelOptions[_vm.form.oilType],
                  function (d, index) {
                    return _c(
                      "a-select-option",
                      { key: index, attrs: { value: d.dictValue } },
                      [_vm._v(_vm._s(d.dictLabel))]
                    )
                  }
                ),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "单价", prop: "price" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入单价" },
                model: {
                  value: _vm.form.price,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "price", $$v)
                  },
                  expression: "form.price",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "当前里程", prop: "mileage" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入当前里程" },
                on: { change: _vm.handleMileageChange },
                model: {
                  value: _vm.form.mileage,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "mileage", $$v)
                  },
                  expression: "form.mileage",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "上传油票拍照", prop: "fuelCouponImage" } },
            [
              _c("file-upload", {
                attrs: { folder: "vehicleFuelFillingRecord", type: "image" },
                model: {
                  value: _vm.form.fuelCouponImage,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "fuelCouponImage", $$v)
                  },
                  expression: "form.fuelCouponImage",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "上传车辆里程实拍", prop: "mileageImage" } },
            [
              _c("file-upload", {
                attrs: { folder: "vehicleFuelFillingRecord", type: "image" },
                model: {
                  value: _vm.form.mileageImage,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "mileageImage", $$v)
                  },
                  expression: "form.mileageImage",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              staticClass: "item-label",
              attrs: { label: "上次加油时里程", prop: "lastMileage" },
            },
            [_c("span", [_vm._v(_vm._s(_vm.form.lastMileage))])]
          ),
          _c(
            "a-form-model-item",
            {
              staticClass: "item-label",
              attrs: { label: "比上次里程新增", prop: "increasedMileage" },
            },
            [_c("span", [_vm._v(_vm._s(_vm.form.increasedMileage))])]
          ),
          _c(
            "a-form-model-item",
            {
              class: [_vm.isRedInfo ? "item-label-red" : ""],
              attrs: {
                label: "最近1次百公里油耗",
                prop: "oilConsumption100kmPer",
              },
            },
            [_c("span", [_vm._v(_vm._s(_vm.form.oilConsumption100kmPer))])]
          ),
          _c(
            "a-form-model-item",
            {
              staticClass: "item-label",
              attrs: {
                label: "最近6次百公里油耗",
                prop: "oilConsumption100kmSix",
              },
            },
            [_c("span", [_vm._v(_vm._s(_vm.form.oilConsumption100kmSix))])]
          ),
          _c(
            "a-form-model-item",
            {
              staticClass: "item-label",
              attrs: {
                label: "相似车型百公里油耗",
                prop: "oilConsumption100kmStandard",
              },
            },
            [_c("span", [_vm._v(_vm._s(_vm.form.oilConsumption100kmStandard))])]
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "本车绑定的加油卡号", prop: "oilCardNo" } },
            [
              _c("a-input", {
                attrs: { placeholder: "", disabled: true },
                model: {
                  value: _vm.form.oilCardNo,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "oilCardNo", $$v)
                  },
                  expression: "form.oilCardNo",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "备注", prop: "remark" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入备注" },
                model: {
                  value: _vm.form.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "remark", $$v)
                  },
                  expression: "form.remark",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "bottom-control" },
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitForm },
                    },
                    [_vm._v(" 保存 ")]
                  ),
                  _c(
                    "a-button",
                    { attrs: { type: "dashed" }, on: { click: _vm.cancel } },
                    [_vm._v(" 取消 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }